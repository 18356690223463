<template>
  <section v-if='hasIpoResults' class='overflow-x-scroll py-8'>
    <div class='flex flex-row justify-start leading-10'>
      <h4>
        <span class='py-2 cursor-pointer' @click='toggleDetails'>
          <component :is='toggleIcon' class='h-4 inline-block inline-block' />
          TMS 체결소계 전송 목록
        </span>
      </h4>
      <label class='px-8 text-gray-600'>Whole 전략
        <input type='checkbox' class='form-checkbox' v-model='isWhole'>
      </label>
    </div>
    <table v-if='showDetails' class='text-sm table-fixed'>
      <thead>
        <tr>
          <th class='text-left'>날짜</th>
          <th class='text-left'>펀드</th>
          <th class='text-left'>계좌</th>
          <th class='text-left'>종목코드</th>
          <th class='text-left'>종목명</th>
          <th class='text-left'>제한</th>
          <th class='text-left'>매수</th>
          <th class='text-right'>수량</th>
          <th class='text-right'>가격</th>
          <th class='text-right'>수수료</th>
          <th class='text-right'>세금</th>
          <th class='text-left'>구분</th>
          <th class='text-left'>전략</th>
          <th class='text-left'>전략할당</th>
          <th class='text-left'>롱숏</th>
        </tr>
      </thead>
      <tbody>
        <ipo-result-transfer-row v-for='result in filteredResults' :key='`old-${result.id}`' quantity-type='old' :result='result' :is-whole='isWhole' />
        <ipo-result-transfer-row v-for='result in filteredResults2' :key='`new-${result.id}`' quantity-type='new' :result='result' :is-whole='isWhole' />
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import IpoResultTransferRow from '@/views/ipo/IpoResultTransferRow.vue'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoResultTransfer',
  components: {
    IpoResultTransferRow,
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      isWhole: false,
      showDetails: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'ipoResults',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    filteredResults () {
      // return this.ipoResults.filter(result => result.total_allocated_quantity > 0)
      return this.ipoResults.filter(result => result.old_allocated_quantity > 0)
    },
    filteredResults2 () {
      return this.ipoResults.filter(result => result.new_allocated_quantity > 0)
    },
    hasIpoResults () {
      return this.filteredResults.length > 0 || this.filteredResults2.length > 0
    },
  },
  methods: {
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply uppercase tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }

</style>
