<template>
  <div class='lg:pb-8 px-2 lg:px-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>Portfolio</h1>
      </div>
    </portal>
    <div class='flex flex-row my-4 w-full overflow-x-auto'>
      <el-date-picker
        v-model='date'
        style='width: 20rem;'
        type='daterange'
        placeholder='투자일자'
        :default-value='date'
        format='yyyy-MM-dd'
        value-format='yyyy-MM-dd'
        @change='selectPortfolios'
        range-separator='~'
        start-placeholder='Start Date'
        end-placeholder='End Date'
        align='right'
        unlink-panels
        :picker-options='pickerOptions'>
      </el-date-picker>
      <input class='form-input ml-2' type='text' placeholder='투자명칭' v-model='investmentName' @keyup.enter='selectPortfolios' />
      <select v-model='collectionStatus' class='form-select ml-2' @change='selectPortfolios'>
        <option value='보유중'>보유중</option>
        <option value='회수완료'>회수완료</option>
        <option value='전체'>전체</option>
      </select>
      <button class='hover:bg-gray-100 rounded-md ml-2 lg:ml-4 py-2 px-2 lg:px-8 border text-sm' @click='selectPortfolios'><refresh-cw-icon class='h-4' /></button>
    </div>
    <div class='portfolio-table-container overflow-auto'>
      <table class='text-base table-fixed'>
        <thead>
          <tr class=''>
            <th class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white sticky top-0 z-10'></th>
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.entity_name'
              keyName='entity_name'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='기업명'
              :optionObject='portfolios'
              class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white z-20 sticky top-0 left-0' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.investment_name'
              keyName='investment_name'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='투자명'
              :optionObject='portfolios'
              class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white sticky top-0 z-10' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.investment_date'
              keyName='investment_date'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='투자일자'
              :optionObject='portfolios'
              class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white sticky top-0 z-10' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.investment_hm_value'
              keyName='investment_hm_value'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='투자금액'
              :optionObject='portfolios'
              class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white sticky top-0 z-10' />
            <th class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white sticky top-0 z-10' style='min-width:16rem;'>주관사</th>
            <th class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white sticky top-0 z-10' style='min-width:32rem;'>비고</th>
            <th class='px-2 py-2 lg:py-3 border text-left uppercase tracking-wide text-sm whitespace-no-wrap bg-white sticky top-0 z-10'>회수여부</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(portfolio, index) in filteredData'
            :key='`portfolio-${index}`'
            class='bg-white hover:bg-blue-100 cursor-pointer'>
            <td @click='openDetail(portfolio)' class='py-1 lg:py-3 pl-2 pr-2 lg:pr-4 border text-xs lg:text-sm whitespace-no-wrap text-right'>{{ index+1 }}</td>
            <td @click='openDetail(portfolio)' class='py-1 lg:py-3 pl-2 pr-2 lg:pr-4 border text-xs lg:text-sm whitespace-no-wrap sticky left-0 bg-white z-10'>{{ portfolio.entity_name }}</td>
            <td @click='openDetail(portfolio)' class='py-1 lg:py-3 pl-2 pr-2 lg:pr-4 border text-xs lg:text-sm whitespace-no-wrap'>{{ portfolio.investment_name }}</td>
            <td @click='openDetail(portfolio)' class='py-1 lg:py-3 pl-2 pr-2 lg:pr-4 border text-xs lg:text-sm whitespace-no-wrap'>{{ portfolio.investment_date }}</td>
            <td class='py-1 lg:py-3 pl-2 pr-2 lg:pr-4 border text-xs lg:text-sm whitespace-no-wrap text-right'>{{ numberStyle(portfolio.investment_hm_value) }} 억원</td>
            <td class='py-1 lg:py-3 pl-2 pr-2 lg:pr-4 border text-xs lg:text-sm whitespace-no-wrap'>
              <input type='text'
                class='text-left w-full'
                v-bind:value='portfolio.underwriter'
                v-on:change='setUnderwriter(portfolio)'>
            </td>
            <td class='py-1 lg:py-3 pl-2 pr-2 lg:pr-4 border text-xs lg:text-sm whitespace-no-wrap'>
              <input type='text'
                class='text-left w-full'
                v-bind:value='portfolio.entity_comment'
                v-on:change='setEntityComment(portfolio)'>
            </td>
            <td class='py-0.5 pl-2 pr-2 border whitespace-no-wrap'>
              <select
                class='form-select ml-2'
                v-bind:value='portfolio.collection_status'
                v-on:change='setCollectionStatus(portfolio)'>
                <option value='보유중'>보유중</option>
                <option value='회수완료'>회수완료</option>
                <option value=''></option>
              </select>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class='bg-gray-100 font-semibold'>
            <th class='px-2 py-2 text-left text-xs lg:text-sm border-b border-l border-r'></th>
            <th class='px-2 py-2 text-left text-xs lg:text-sm border-b border-r'>합계</th>
            <th class='px-2 py-2 text-left text-xs lg:text-sm border-b border-r'></th>
            <th class='px-2 py-2 text-left text-xs lg:text-sm border-b border-r'></th>
            <th class='px-4 py-2 text-right text-xs lg:text-sm border-b border-r'>{{ numberStyle(sumInvestmentHmValue) }} 억원</th>
            <th class='px-2 py-2 text-left text-xs lg:text-sm border-b border-r'></th>
            <th class='px-2 py-2 text-left text-xs lg:text-sm border-b border-r'></th>
            <th class='px-2 py-2 text-left text-xs lg:text-sm border-b border-r'></th>
          </tr>
        </tfoot>
      </table>
      <portal to='modals'>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState }    from 'vuex'
import { RefreshCwIcon } from 'vue-feather-icons'
import dayjs                       from 'dayjs'
import TableHeaderFilterSelect     from '@/components/TableHeaderFilterSelect.vue'
import EntitiesApi                 from '@/api/v1/entities'


export default {
  name: 'Portfolios',
  components: {
    RefreshCwIcon,
    TableHeaderFilterSelect,
  },
  data () {
    return {
      investmentName: '',
      sortDir: '',
      sortKey: '',
      collectionStatus: '보유중',
      filterTexts: {
        investment_name: [],
        entity_name: [],
        investment_date: [],
        investment_hm_value: [],
      },
      filterKeys: [
        'investment_name',
        'entity_name',
        'investment_date',
        'investment_hm_value',
      ],
      date: ['2014-01-01', dayjs().format('YYYY-MM-DD')],
      pickerOptions: {
        shortcuts: [{
          text: '금년',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0, 1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '6개월',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '1년',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '3년',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().setFullYear(new Date().getFullYear() - 3));
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    ...mapState('portfolios', [
      'portfolios',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
      'dashboardViews',
    ]),
    sortedData () {
      if (this.sortDir === '') {
        return this.portfolios
      } else {
        this.sortPortfolios ( {key: this.sortKey, dir: this.sortDir} )
        return this.portfolios
      }
    },
    filteredData () {
      return this.sortedData.filter ( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
    },
    sumInvestmentHmValue () {
      let sumValue = 0
      this.portfolios.forEach(portfolio => {
        sumValue += portfolio.investment_hm_value
      })
      return sumValue
    }
  },
  methods: {
    ...mapActions('portfolios', [
      'getGroupPortfolios',
      'portfolioDetailsOpen',
      'sortPortfolios',
      'portfolioSetEntityComment',
      'portfolioSetUnderwriter',
      'portfolioSetCollectionStatus',
    ]),
    ...mapActions('investments', [
      'getInvestmentData',
    ]),
    ...mapActions('dashboardViews', [
      'getDashboardViews',
      'selectDashboardView',
    ]),
    filterCollectionPortfolios () {
      return this.collectionStatus === '전체' ? this.portfolios : this.portfolios.filter(portfolio => portfolio.collection_status === this.collectionStatus)
    },
    selectPortfolios () {
      this.getGroupPortfolios ( {filter: this.investmentName, collection_status: this.collectionStatus, start_date: dayjs(this.date[0]).format('YYYYMMDD'), end_date: dayjs(this.date[1]).format('YYYYMMDD')} ).then(() => {
      })
    },
    openDetail (portfolio) {
      this.setListEditView()
      this.getInvestmentData(portfolio.investment_id).then(() => {
        this.portfolioDetailsOpen(portfolio)
      })
    },
    numberStyle (number) {
      return number ? Number(number).toLocaleString() : 0
    },
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
    setListEditView () {
      if (this.currentDashboardView.view_layout === 'List Edit' && this.currentDashboardView.dashboard_view_name.substring(0,4) === 'Deal') {
        return
      }

      if (this.dashboardViews.length === 0) {
        this.getDashboardViews ()
      }

      for (var ii = 0; ii < this.dashboardViews.length; ii++) {
        if (this.dashboardViews[ii].view_layout === 'List Edit' && this.dashboardViews[ii].dashboard_view_name.substring(0,4) === 'Deal') {
          this.selectDashboardView(this.dashboardViews[ii])
          break
        }
      }
      return
    },
    setUnderwriter (portfolio) {
      let underwriterValue = { id: portfolio.entity_id, underwriter: event.target.value }

      EntitiesApi.updateEntityComment(underwriterValue).then((resp) => {
        if (resp !== 'ERROR') {
          this.portfolioSetUnderwriter({entity_id: portfolio.entity_id, underwriter: resp})
        }
      })
    },
    setEntityComment (portfolio) {
      let commentValue = { id: portfolio.entity_id, entity_comment: event.target.value }

      EntitiesApi.updateEntityComment(commentValue).then((resp) => {
        if (resp !== 'ERROR') {
          this.portfolioSetEntityComment({entity_id: portfolio.entity_id, entityComment: resp})
        }
      })
    },
    setCollectionStatus (portfolio) {
      let collectionStatusValue = { id: portfolio.entity_id, collection_status: event.target.value }

      EntitiesApi.updateEntityComment(collectionStatusValue).then((resp) => {
        if (resp !== 'ERROR') {
          this.portfolioSetCollectionStatus({entity_id: portfolio.entity_id, collectionStatus: resp})
        }
      })
    },
  },
  mounted () {
    this.selectPortfolios ()
  },
}
</script>

<style lang='scss' scoped>
  .portfolio-table-container {
    height: calc(100vh - 10rem);
  }

  @media (min-width: 1024px) {
    tr:hover td {
      @apply bg-blue-100;
    }
    .portfolio-table-container {
      height: calc(100vh - 12rem);
    }
  }
</style>
